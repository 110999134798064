import _ from 'lodash';
import routePaths from '../lib/routePaths';
import { getCardIconName } from '../widgets/equivalence-widget-template/components/helpers';
import { EQUIVALENCES } from '../widgets/equivalence-widget-template/types';

const url = process.env.REACT_APP_API_URL as string;
export const enum API_PROVIDER {
  ALSO_ENERGY = 'ALSO_ENERGY',
  SMA = 'SMA',
  OPEN_METEO = 'OPEN_METEO'
}

const sitesConfigList = [
  {
    name: 'default',
    apiProvider: API_PROVIDER.ALSO_ENERGY,
    chartId: 15,
    title: 'Solar Dashboard',
    description: 'Solar Dashboard'
  },
  {
    name: 'phil-expo-center',
    domain: 'https://phillyexpocenter-solardashboard.corbinbrooks.com',
    hostname: 'phillyexpocenter-solardashboard.corbinbrooks.com',
    siteId: 62751,
    chartId: 15,
    lifetimeProductionUnit: 'megawatt', //Options should be: kilowatt, megawatt, gigawatt
    showYearProduction: false,
    showChartLabels: true,
    showChartDots: false,
    chartCategories: [],
    title: 'The Greater Philadelphia Expo Center',
    subTitle: 'ROOFTOP SOLAR PROJECT',
    titleColor: '#05436A',
    titleBackgroundColor: '#0BB3D7',
    dashboardBackgroundColor: '#144C7F',
    tabTitle: 'Solar Dashboard - Philly Expo Center',
    menu: ['home', 'graph'],
    fontFamily: 'Montserrat',
    bottomInfo: ['size', 'since', 'lastUpdate', 'capacity', 'count'],
    moduleCount: '2,472',
    capacity: '485 watt',
    logo: '/images/gpec_logo.svg',
    icon: '/images/philly_favicon.ico',
    overrideCardHeight: 246,
    generatingSinceDateFormat: 'll',
    generatingSinceData: 'Nov 8, 2022',
    lastUpdateDateFormat: 'h:mm A MMM DD, YY',
    meterColor: '#DB8F22',
    capitalizeTitle: true,
    qrCodes: [
      {
        text: 'To access Greater Philadelphia Expo Center Website',
        url: 'https://phillyexpocenter.com/',
        component: 'sidebar'
      }
    ],
    sideBar: {
      width: 253,
      logoSize: 221,
      linksFontSize: 20,
      infoFontSize: 14,
      iconSize: 24,
      qrTitleFontSize: 20,
      qrTextFontSize: 14
    },
    qrPosition: 'top',
    systemDataColor: '#0BB3D7',
    wheelColor: { start: '#34B53A', end: '#E8E8E8' },
    showForecast: false,
    headerCardColor: '#0BB3D7',
    valueColorCard: '#333333',
    iconColor: '#DB8E22',
    cardColor: '#DB8F22',
    showRealTimeTitle: true,
    showMonthValues: true,
    bottomCards: [
      {
        type: 'equivalency',
        value: 'Cars'
      },
      {
        type: 'equivalency',
        value: 'Homes'
      },
      {
        type: 'equivalency',
        value: 'Trees'
      },
      {
        type: 'image',
        value: '/images/gpec_image_1_.jpg'
      }
    ]
  },
  {
    name: 'buckingham-companies',
    domain: 'https://buckingham-solarkiosk.corbinbrooks.com',
    hostname: 'buckingham-solarkiosk.corbinbrooks.com',
    siteId: 64409,
    sites: [64409, 64410],
    chartCategories: [],
    showMultipleSitesBottomInfo: true,
    showYearProduction: false,
    chartId: 15,
    showChartLabels: true,
    showChartDots: false,
    lineGraphWidth: 3,
    title: 'Buckingham Companies Solar Arrays',
    tabTitle: 'Buckingham Solar Kiosk',
    menu: ['home', 'graph', 'sites'],
    fontFamily: 'Poppins',
    bottomInfo: ['size', 'lastUpdate'],
    logo: '/images/bc_logo.svg',
    icon: '/images/buckingham-favicon.ico',
    iconColor: '#809E6A',
    cardColor: '#334D6E',
    meterColor: '#334D6E',
    qrCodes: [],
    wheelColor: {},
    showForecast: true,
    valueColorCard: '#809E6A',
    showRealTimeTitle: true,
    showMonthValues: false,
    showNetValues: true,
    bottomCardsBefore: [
      {
        type: 'equivalency',
        value: 'Natural Gas'
      },
      {
        type: 'equivalency',
        value: 'Water'
      },
      {
        type: 'equivalency',
        value: 'Trees'
      },
      {
        type: 'equivalency',
        value: 'Methane'
      }
    ],
    bottomCards: []
  },
  {
    name: 'florence',
    domain: 'https://florence-solardashboard.corbinbrooks.com',
    hostname: 'florence-solardashboard.corbinbrooks.com',
    siteId: 56128,
    chartId: 15,
    lifetimeProductionUnit: 'gigawatt', //Options should be: kilowatt, megawatt, gigawatt
    showYearProduction: false,
    showChartLabels: true,
    showChartDots: false,
    chartCategories: [],
    lineGraphWidth: 3,
    title: 'Florence HS: Solar',
    tabTitle: 'Florence Solar Dashboard',
    fontFamily: 'Poppins',
    menu: ['home', 'graph', 'howTo', 'system'],
    bottomInfo: ['size', 'since', 'lastUpdate'],
    logo: '/images/florence_logo.svg',
    icon: '/images/florence-favicon.png',
    generatingSinceDateFormat: 'll',
    generatingSinceData: 'Mar 9, 2021',
    lastUpdateDateFormat: 'h:mm A MMM DD, YY',
    cardColor: '#2C65F1',
    iconColor: '#809E6A',
    meterColor: '#F2DF71',
    meterFontColor: '#333333',
    qrCodes: [],
    wheelColor: {},
    showForecast: true,
    valueColorCard: '#809E6A',
    showRealTimeTitle: true,
    showMonthValues: false,
    bottomCards: [
      {
        type: 'equivalency',
        value: 'Trees'
      },
      {
        type: 'equivalency',
        value: 'Gas'
      },
      {
        type: 'image',
        value: '/images/PV_array_from_south-alt.jpg',
        size: 6
      }
    ],
    footerLogo: {
      systemPage: {
        logo: '/images/florence_system_footer_logo.svg'
      }
    }
  },
  {
    name: 'winona',
    domain: 'https://winona-solardashboard.corbinbrooks.com',
    hostname: 'winona-solardashboard.corbinbrooks.com',
    siteId: 60988,
    sites: [60988, 60989, 60990, 60991, 60992, 60993, 60994],
    showIndTitle: true,
    showBackMapButton: true,
    showAltHowSolarWorks: true,
    showMultipleSitesBottomInfo: false,
    chartId: 15,
    screenReader: {
      active: true,
      screenReaderDashboardName: 'Winona State University'
    },
    showNetValues: true,
    lifetimeProductionUnit: 'megawatt', //Options should be: kilowatt, megawatt, gigawatt
    yearProductionUnit: 'megawatt', //Options should be: kilowatt, megawatt, gigawatt
    showYearProduction: true,
    showChartLabels: true,
    showChartDots: false,
    chartCategories: [],
    lineGraphWidth: 3,
    title: 'Winona State University: Summary Dashboard All Locations Combined',
    singleDashboardTitle: 'Winona State University',
    tabTitle: 'WSU Solar Dashboard',
    fontFamily: 'Poppins',
    summaryMenu: ['summary-home', 'summary-graph'],
    menu: ['home', 'graph', 'howTo', 'map'],
    bottomInfo: ['lastUpdate'],
    logo: `${url}/images/wsu_logo.svg`,
    icon: `${url}/images/winona-favicon.ico`,
    generatingSinceDateFormat: 'll',
    lastUpdateDateFormat: 'h:mm A MMM DD, YY',
    cardColor: '#4B08A1',
    iconColor: '#809E6A',
    meterColor: '#4B08A1',
    meterFontColor: '#FFFFFF',
    mobileMenuColor: 'rgba(241, 241, 241, 0.96)',
    qrCodes: [
      {
        text: 'To access the Dashboard on mobile',
        url: 'https://winona-solardashboard.corbinbrooks.com',
        component: 'sidebar'
      }
    ],
    qrPosition: 'bottom',
    qrMargin: '1rem 1rem 2.5rem',
    qrSizeDifferentClass: true,
    wheelColor: {},
    showForecast: false,
    valueColorCard: '#333333',
    showRealTimeTitle: false,
    showMonthValues: false,
    bottomCardsBefore: [
      {
        type: 'equivalency',
        value: 'Trees'
      },
      {
        type: 'equivalency',
        value: 'Carbon'
      },
      {
        type: 'equivalency',
        value: 'Gas'
      },
      {
        type: 'equivalency',
        value: 'Homes'
      }
    ],
    bottomCards: [],
    logosArr: [
      {
        id: 60988,
        src: `${url}/images/winona-logos/Haake-kiosk.svg`
      },
      {
        id: 60989,
        src: `${url}/images/winona-logos/IWC-hellble-kiosk.svg`
      },
      {
        id: 60990,
        src: `${url}/images/winona-logos/Kirkland-kiosk.svg`
      },
      {
        id: 60991,
        src: `${url}/images/winona-logos/McCown-kiosk.svg`
      },
      {
        id: 60992,
        src: `${url}/images/winona-logos/IWC-rooftop-kiosk.svg`
      },
      {
        id: 60993,
        src: `${url}/images/winona-logos/wabasha-kiosk.svg`
      },
      {
        id: 60994,
        src: `${url}/images/winona-logos/IWC-carport-kiosk.svg`
      }
    ]
  },
  {
    name: 'discovery',
    domain: 'https://discoveryes-solardashboard.corbinbrooks.com',
    hostname: 'discoveryes-solardashboard.corbinbrooks.com',
    siteId: 61285,
    smaApi: {
      plantId: 7322562,
      deviceId: 7322572,
      deviceIds: {
        RGM_SP: 11598649,
        RGM_NC: 11598650
      }
    },
    location: {
      longitude: -77.47789,
      latitude: 39.01902
    },
    lifetimeProductionUnit: 'kilowatt', //Options should be: kilowatt, megawatt, gigawatt
    wheelColor: { start: '#FAFAFA', end: '#0A3764' },
    meterColor: '#0A3764',
    meterFontColor: '#fff',
    bottomCardsColor: '#0A3764',
    howToActiveLinkBgColor: '#0A3764',
    howToActiveLinkBorderDisabled: false,
    bottomCardsIconColor: '#3B5F83',
    weatherIconColor: '#0A3764',
    chartId: 15,
    sidebarLogoSize: '120px',
    showYearProduction: false,
    showChartLabels: true,
    showChartDots: false,
    chartCategories: ['RGM1-SP', 'RGM2-NC'],
    chartLeyendAlignment: 'left', // left | center |right
    lineGraphWidth: 3,
    sideBar: {
      infoFontSize: 14,
      climateEnabled: true,
      climate: {
        title: 'Current Weather',
        iconPosition: 'top'
      },
      showForecast: false
    },
    graphPageTitle: 'Discovery ES: Solar',
    hardwareIdWeeklySystem: [444047, 444048],
    showForecast: true,
    title: 'Discovery ES: Solar',
    tabTitle: 'Discovery ES Dashboard',
    logo: `${url}/images/discovery-logos/discovery_logo.svg`,
    icon: `${url}/images/discovery-logos/discovery-favicon.ico`,
    bottomCardConfig: {
      images: {
        card4: `${url}/images/discovery-logos/discovery-bottom-card-image1.png`
      },
      topTitle: 'Total energy generated equals',
      hideTopIcon: true,
      iconsOverride: [
        {
          type: EQUIVALENCES.CARBON,
          iconName: getCardIconName('Carbon Dioxide')
        }
      ]
    },
    bottomImageGraphPage: `${url}/images/discovery-logos/discovery_school_logo_footer.svg`,
    renameChartLabels: true,
    rotateSeconds: 10,
    howToPages: [
      `/howto/${routePaths.public.howto.sunSolarEnergy}`,
      `/howto/${routePaths.public.howto.panelInverter}`,
      `/howto/${routePaths.public.howto.meterGrid}`,
      `/howto/${routePaths.public.howto.summary}`
    ]
  },
  {
    name: 'broadrun',
    domain: 'https://broadrunhs-solardashboard.corbinbrooks.com',
    hostname: 'broadrunhs-solardashboard.corbinbrooks.com',
    siteId: 10769299,
    smaApi: {
      plantId: 10769299,
      deviceId: 7322572,
      deviceIds: {
        RGM_SP: 10786755,
        RGM_NC: 10786756
      }
    },
    location: {
      longitude: -77.49081,
      latitude: 39.02281
    },
    lifetimeProductionUnit: 'kilowatt', //Options should be: kilowatt, megawatt, gigawatt
    mainColor: '#5E0000',
    // consumptionBars: {
    //   leftBar: {
    //     primaryColor: '#3b5f83',
    //     secondaryColor: '#00B050',
    //   },
    //   rightBar: {
    //     primaryColor: '#3b5f83',
    //     secondaryColor: '#F51D1D',
    //   }
    // },
    // graphColor: '#5E0000',
    wheelColor: { start: '#FAFAFA', end: '#5E0000' },
    meterColor: '#5E0000',
    meterFontColor: '#fff',
    bottomCardsColor: '#5E0000',
    howToActiveLinkBgColor: '#5E0000',
    howToActiveLinkBorderDisabled: false,
    bottomCardsIconColor: '#003478',
    weatherIconColor: '#5E0000',
    systemDataColor: '#5E0000',
    bottomCardTitleStyles: {
      fontColor: '#5E0000'
    },
    chartId: 15,
    sidebarLogoSize: '120px',
    showYearProduction: false,
    showChartLabels: true,
    showChartDots: false,
    chartCategories: ['RGM1-SP', 'RGM2-NC'],
    chartLeyendAlignment: 'left', // left | center |right
    lineGraphWidth: 3,
    sideBar: {
      infoFontSize: 14,
      climateEnabled: true,
      climate: {
        title: 'Current Weather',
        iconPosition: 'top'
      },
      showForecast: false
    },
    graphPageTitle: 'Broad Run HS: Solar',
    hardwareIdWeeklySystem: [444047, 444048],
    showForecast: true,
    title: 'Broad Run HS: Solar',
    tabTitle: 'Broad Run HS Dashboard',
    logo: `${url}/images/broadrun/logo.svg`,
    icon: `${url}/images/broadrun/favicon.ico`,
    bottomCardConfig: {
      images: {
        card4: `${url}/images/broadrun/bottom-card-image.png`
      },
      topTitle: 'Total energy generated equals',
      hideTopIcon: true,
      iconsOverride: [
        {
          type: EQUIVALENCES.CARBON,
          iconName: getCardIconName('Carbon Dioxide')
        }
      ]
    },
    // bottomImageGraphPage: `${url}/images/discovery-logos/discovery_school_logo_footer.svg`,
    renameChartLabels: true,
    rotateSeconds: 10,
    howToPages: [
      `/howto/${routePaths.public.howto.sunSolarEnergy}`,
      `/howto/${routePaths.public.howto.panelInverter}`,
      `/howto/${routePaths.public.howto.meterGrid}`,
      `/howto/${routePaths.public.howto.summary}`
    ]
  },
  {
    name: 'evergreen',
    domain: 'https://evergreen-solardashboard.corbinbrooks.com',
    hostname: 'evergreen-solardashboard.corbinbrooks.com',
    siteId: 10786659,
    smaApi: {
      plantId: 10786659,
      deviceId: 10786663,
      deviceIds: {
        RGM_SP: 10786681,
        RGM_NC: 10786680
      }
    },
    location: {
      longitude: -77.572778,
      latitude: 39.090922
    },
    lifetimeProductionUnit: 'kilowatt', //Options should be: kilowatt, megawatt, gigawatt
    mainColor: '#004812',
    wheelColor: { start: '#FAFAFA', end: '#004812' },
    meterColor: '#004812',
    meterFontColor: '#fff',
    bottomCardsColor: '#004812',
    howToActiveLinkBgColor: '#004812',
    howToActiveLinkBorderDisabled: false,
    bottomCardsIconColor: '#003478',
    weatherIconColor: '#004812',
    systemDataColor: '#004812',
    bottomCardTitleStyles: {
      fontColor: '#004812'
    },
    chartId: 15,
    sidebarLogoSize: '120px',
    showYearProduction: false,
    showChartLabels: true,
    showChartDots: false,
    chartCategories: ['RGM1-SP', 'RGM2-NC'],
    chartLeyendAlignment: 'left', // left | center |right
    lineGraphWidth: 3,
    sideBar: {
      infoFontSize: 14,
      climateEnabled: true,
      climate: {
        title: 'Current Weather',
        iconPosition: 'top'
      },
      showForecast: false
    },
    graphPageTitle: 'Evergreen Mill: Solar',
    hardwareIdWeeklySystem: [444047, 444048],
    showForecast: true,
    title: 'Evergreen Mill: Solar',
    tabTitle: 'Evergreen Mill Dashboard',
    logo: `${url}/images/evergreen/logo.png`,
    icon: `${url}/images/evergreen/favicon.ico`,
    bottomCardConfig: {
      images: {
        card4: `${url}/images/evergreen/bottom-card-image.png`
      },
      topTitle: 'Total energy generated equals',
      hideTopIcon: true,
      iconsOverride: [
        {
          type: EQUIVALENCES.CARBON,
          iconName: getCardIconName('Carbon Dioxide')
        }
      ]
    },
    // bottomImageGraphPage: `${url}/images/discovery-logos/discovery_school_logo_footer.svg`,
    renameChartLabels: true,
    rotateSeconds: 10,
    howToPages: [
      `/howto/${routePaths.public.howto.sunSolarEnergy}`,
      `/howto/${routePaths.public.howto.panelInverter}`,
      `/howto/${routePaths.public.howto.meterGrid}`,
      `/howto/${routePaths.public.howto.summary}`
    ]
  },
  {
    name: 'frederick-douglass',
    domain: 'https://frederickdouglasses-solardashboard.corbinbrooks.com',
    hostname: 'frederickdouglasses-solardashboard.corbinbrooks.com',
    siteId: 61283,
    smaApi: {
      plantId: 7178690,
      deviceId: 7222229,
      deviceIds: {
        RGM_SP: 11239275,
        RGM_NC: 11239276
      }
    },
    location: {
      longitude: -77.55308,
      latitude: 39.10738
    },
    lifetimeProductionUnit: 'kilowatt', //Options should be: kilowatt, megawatt, gigawatt
    wheelColor: { start: '#FAFAFA', end: '#0A3764' },
    meterColor: '#0A3764',
    meterFontColor: '#fff',
    bottomCardsColor: '#0A3764',
    howToActiveLinkBgColor: '#334D6E',
    howToActiveLinkBorderDisabled: false,
    bottomCardsIconColor: '#3B5F83',
    weatherIconColor: '#0A3764',
    chartId: 15,
    sidebarLogoSize: '120px',
    showYearProduction: false,
    showChartLabels: true,
    showChartDots: false,
    chartCategories: [], // ['RGM1-SP', 'RGM2-NC'] to be updated, waiting for AEs response
    chartLeyendAlignment: 'left', // left | center |right
    lineGraphWidth: 3,
    sideBar: {
      infoFontSize: 14,
      climateEnabled: true,
      climate: {
        title: 'Current Weather',
        iconPosition: 'top'
      },
      showForecast: false
    },
    graphPageTitle: 'Frederick Douglass ES: Solar',
    hardwareIdWeeklySystem: /*[447173, 447174]*/ [444046, 444045],
    showForecast: true,
    title: 'Frederick Douglass ES: Solar',
    tabTitle: 'Frederick Douglass Dashboard',
    logo: `${url}/images/frederick-logos/frederick_logo.svg`,
    icon: `${url}/images/frederick-logos/frederick-favicon.ico`,
    bottomCardConfig: {
      images: {
        card4: `${url}/images/frederick-logos/frederick-bottom-card-image1.svg`
      },
      topTitle: 'Total energy generated equals',
      hideTopIcon: true,
      iconsOverride: [
        {
          type: EQUIVALENCES.CARBON,
          iconName: getCardIconName('Carbon Dioxide')
        }
      ]
    },
    bottomImageGraphPage: `${url}/images/frederick-logos/frederick_school_logo_footer.svg`,
    renameChartLabels: true,
    rotateSeconds: 10,
    howToPages: [
      `/howto/${routePaths.public.howto.sunSolarEnergy}`,
      `/howto/${routePaths.public.howto.panelInverter}`,
      `/howto/${routePaths.public.howto.meterGrid}`,
      `/howto/${routePaths.public.howto.summary}`
    ]
  },
  {
    name: 'endress',
    domain: 'https://endresshauser-solardashboard.corbinbrooks.com',
    hostname: 'endresshauser-solardashboard.corbinbrooks.com',
    siteId: 64604,
    chartId: 15,
    lifetimeProductionUnit: 'gigawatt', //Options should be: kilowatt, megawatt, gigawatt
    showYearProduction: false,
    showChartLabels: true,
    showChartDots: false,
    chartCategories: [],
    lineGraphWidth: 3,
    title: 'Endress + Hauser Flow USA: Solar PV',
    tabTitle: 'Solar Dashboard – Endress + Hauser',
    fontFamily: 'Poppins',
    menu: ['home', 'graph', 'howTo'],
    bottomInfo: ['size', 'since', 'lastUpdate'],
    logo: '/images/endress/endress-logo.svg',
    icon: '/images/endress/favicon.ico',
    overrideCardHeight: 246,
    generatingSinceDateFormat: 'll',
    generatingSinceData: 'Mar 9, 2021',
    lastUpdateDateFormat: 'h:mm A MMM DD, YY',
    cardColor: '#0099FF',
    iconColor: '#809E6A',
    meterColor: '#0099FF',
    meterFontColor: '#ffffff',
    climateIconsColor: '#0099FF',
    howToActiveLinkBgColor: '#0099FF',
    howToActiveLinkBorderDisabled: true,
    qrCodes: [],
    wheelColor: {},
    showForecast: true,
    valueColorCard: '#333333',
    showRealTimeTitle: true,
    showMonthValues: true,
    imageInHomeAtRightSide: '/images/endress/endress-company-image.svg',
    rotateSeconds: 10,
    bottomCards: [
      {
        type: 'equivalency',
        value: 'Trees'
      },
      {
        type: 'equivalency',
        value: 'Flow Meters'
      },
      {
        type: 'equivalency',
        value: 'Gas'
      },
      {
        type: 'equivalency',
        value: 'Carbon'
      }
    ]
  },
  {
    name: 'rolls-royce',
    domain: 'https://rollsroyce-solardashboard.corbinbrooks.com',
    hostname: 'rollsroyce-solardashboard.corbinbrooks.com',
    siteId: 52454,
    // In case theres a dashboard that need to show different sites but not sum their respective values
    sitesToShow: [
      {
        id: 39797,
        singleDashboardTitle: '1MW Solar Array Production',
        spanSelected: 'week',
        binSelected: 'Bin1Hour',
        startDateSelected: 6,
        hideMeteredProductionText: true,
        showRollsRoyceLeyendStyles: true,
        installDateSelected: '2017-04-19T00:00:00-04:00',
        showAnnualRollsRoyceLeyendStyles: true
      },
      {
        id: 52454,
        singleDashboardTitle: '650 kW Canopy Solar Array Production',
        spanSelected: 'week',
        binSelected: 'Bin1Hour',
        startDateSelected: 6,
        hideMeteredProductionText: true,
        showRollsRoyceLeyendStyles: true,
        installDateSelected: '2019-12-09T00:00:00-05:00',
        showAnnualRollsRoyceLeyendStyles: true
      }
    ],
    ////
    chartId: 15,
    lifetimeProductionUnit: 'gigawatt', //Options should be: kilowatt, megawatt, gigawatt
    showAltRoutes: {
      altHome: true
    },
    showYearProduction: false,
    showChartLabels: true,
    showTwoGraphsAltStyle: true,
    showChartDots: false,
    chartCategories: [],
    lineGraphWidth: 3,
    barGraphSize: 100,
    tabTitle: 'Rolls Royce Solar Dashboard',
    fontFamily: 'Poppins',
    menu: ['site-aerial', '1mw-solar', 'canopy-solar', '7-day-graph', 'annual-prod'],
    bottomInfo: ['size', 'since', 'lastUpdate'],
    logo: `${url}/images/rolls-royce/RR-logo.png`,
    icon: `${url}/images/rolls-royce/RR-favicon.ico`,
    siteOverviewImage: `${url}/images/rolls-royce/RR-Site-Overview.svg`,
    generatingSinceDateFormat: 'll',
    lastUpdateDateFormat: 'h:mm A MMM DD, YY',
    cardColor: '#10069F',
    iconColor: '#809E6A',
    meterColor: '#10069F',
    meterFontColor: '#ffffff',
    climateIconsColor: '#10069F',
    qrCodes: [],
    mobileMenuColor: 'rgba(255, 255, 255, 0.9)',
    wheelColor: {},
    showForecast: true,
    valueColorCard: '#333333',
    showRealTimeTitle: true,
    bottomCardsBefore: [
      {
        type: 'equivalency',
        value: 'Trees'
      },
      {
        type: 'equivalency',
        value: 'Gas'
      },
      {
        type: 'equivalency',
        value: 'Carbon'
      },
      {
        type: 'equivalency',
        value: 'Homes'
      }
    ],
    bottomCards: [],
    oldDashVersion: true,
    rotateSeconds: 6,
    rotatePages: [
      {
        path: routePaths.public.home,
        duration: 6
      },
      {
        path: `${routePaths.public.singleDash}`.replace(':id', '39797'),
        duration: 11
      },
      {
        path: `${routePaths.public.singleDash}`.replace(':id', '52454'),
        duration: 11
      },
      {
        path: routePaths.public.graph,
        duration: 11
      },
      {
        path: routePaths.public.annualGraph,
        duration: 15
      }
    ]
  },
  {
    name: 'j-lumpton-simpson',
    domain: 'https://jlsimpsonms-solardashboard.corbinbrooks.com',
    hostname: 'jlsimpsonms-solardashboard.corbinbrooks.com',
    siteId: 61270,
    smaApi: {
      plantId: 5849936,
      deviceId: 7000509,
      deviceIds: {
        RGM_SP: 11808146,
        RGM_NC: 11808147
      }
    },
    location: {
      longitude: -77.23576,
      latitude: 38.87662
    },
    lifetimeProductionUnit: 'kilowatt', //Options should be: kilowatt, megawatt, gigawatt
    wheelColor: { start: '#FAFAFA', end: '#0A3764' },
    meterColor: '#0A3764',
    meterFontColor: '#fff',
    bottomCardsColor: '#0A3764',
    howToActiveLinkBgColor: '#0A3764',
    howToActiveLinkBorderDisabled: false,
    bottomCardsIconColor: '#3B5F83',
    weatherIconColor: '#0A3764',
    chartId: 15,
    sidebarLogoSize: '120px',
    showYearProduction: false,
    showChartLabels: true,
    showChartDots: false,
    chartCategories: ['RGM1-SP', 'RGM2-NC'],
    chartLeyendAlignment: 'left', // left | center |right
    lineGraphWidth: 3,
    sideBar: {
      infoFontSize: 14,
      climateEnabled: true,
      climate: {
        title: 'Current Weather',
        iconPosition: 'top'
      },
      showForecast: false
    },
    graphPageTitle: 'J. Lupton Simpson MS: Solar',
    hardwareIdWeeklySystem: [444046, 444045],
    showForecast: true,
    title: 'J. Lupton Simpson MS: Solar',
    tabTitle: 'J. Lupton Simpson MS: Solar Dashboard',
    logo: `${url}/images/jl-simpson/jl-simpson-logo.svg`,
    icon: `${url}/images/jl-simpson/jl-simpson-favicon.ico`,
    bottomCardConfig: {
      images: {
        card4: `${url}/images/jl-simpson/jl-simpson-image.svg`
      },
      topTitle: 'Total energy generated equals',
      hideTopIcon: true,
      iconsOverride: [
        {
          type: EQUIVALENCES.CARBON,
          iconName: getCardIconName('Carbon Dioxide')
        }
      ]
    },
    bottomImageGraphPage: `${url}/images/jl-simpson/jl-simpson-graph.png`,
    renameChartLabels: true,
    rotateSeconds: 10,
    howToPages: [
      `/howto/${routePaths.public.howto.sunSolarEnergy}`,
      `/howto/${routePaths.public.howto.panelInverter}`,
      `/howto/${routePaths.public.howto.meterGrid}`,
      `/howto/${routePaths.public.howto.summary}`
    ]
  },
  {
    name: 'madison-trust',
    domain: 'https://madisonstrustes-solardashboard.corbinbrooks.com',
    hostname: 'madisonstrustes-solardashboard.corbinbrooks.com',
    siteId: 65836,
    smaApi: {
      plantId: 10451363,
      deviceId: 10451374,
      deviceIds: {
        RGM_SP: 10452917,
        RGM_NC: 10452916
      }
    },
    location: {
      longitude: -77.54447,
      latitude: 38.97769
    },
    lifetimeProductionUnit: 'kilowatt', //Options should be: kilowatt, megawatt, gigawatt
    wheelColor: { start: '#FAFAFA', end: '#8E1005' },
    meterColor: '#8E1005',
    meterFontColor: '#fff',
    bottomCardsColor: '#8E1005',
    howToActiveLinkBgColor: '#8E1005',
    howToActiveLinkBorderDisabled: false,
    bottomCardsIconColor: '#8E1005',
    weatherIconColor: '#0A3764',
    chartId: 15,
    sidebarLogoSize: '120px',
    showYearProduction: false,
    showChartLabels: true,
    showChartDots: false,
    chartCategories: ['RGM1-SP', 'RGM2-NC'],
    chartLeyendAlignment: 'left', // left | center |right
    lineGraphWidth: 3,
    sideBar: {
      infoFontSize: 14,
      climateEnabled: true,
      climate: {
        title: 'Current Weather',
        iconPosition: 'top'
      },
      showForecast: false
    },
    graphPageTitle: 'Madison’s Trust ES: Solar',
    hardwareIdWeeklySystem: [447181, 447182],
    showForecast: true,
    title: 'Madison’s Trust ES: Solar',
    tabTitle: 'Madison Trust Dashboard',
    logo: `${url}/images/madison-trust/madison-trust-logo.svg`,
    icon: `${url}/images/madison-trust/madison-trust-favicon.ico`,
    bottomCardConfig: {
      images: {
        card4: `${url}/images/madison-trust/madison-card-image.svg`
      },
      topTitle: 'Total energy generated equals',
      hideTopIcon: true,
      iconsOverride: [
        {
          type: EQUIVALENCES.CARBON,
          iconName: getCardIconName('Carbon Dioxide')
        }
      ]
    },
    bottomImageGraphPage: `${url}/images/madison-trust/madison-trust-graph-tag.svg`,
    rotateSeconds: 30,
    rotatePages: [
      {
        path: routePaths.public.home,
        duration: 30
      },
      {
        path: routePaths.public.graph,
        duration: 15
      },
      {
        path: `/howto/${routePaths.public.howto.sunSolarEnergy}`,
        duration: 15
      },
      {
        path: `/howto/${routePaths.public.howto.panelInverter}`,
        duration: 15
      },
      {
        path: `/howto/${routePaths.public.howto.meterGrid}`,
        duration: 15
      },
      {
        path: `/howto/${routePaths.public.howto.summary}`,
        duration: 15
      }
    ]
  },
  {
    // currently used
    name: 'pit-black-diamond',
    domain: 'https://blackdiamondhs-solardashboard.corbinbrooks.com',
    hostname: 'blackdiamondhs-solardashboard.corbinbrooks.com',
    siteId: 56215,
    lifetimeProductionUnit: 'gigawatt', //Options should be: kilowatt, megawatt, gigawatt
    wheelColor: {},
    meterColor: '#23772D',
    meterFontColor: '#fff',
    bottomCardsColor: '#23772D',
    howToActiveLinkBgColor: '#23772D',
    howToActiveLinkBorderDisabled: true,
    bottomCardsIconColor: '#809E6A',
    weatherIconColor: '#23772D',
    chartId: 15,
    showYearProduction: false,
    showChartLabels: true,
    showChartDots: false,
    chartCategories: [],
    chartLeyendAlignment: 'left', // left | center |right
    lineGraphWidth: 3,
    title: 'Black Diamond High School',
    tabTitle: 'Black Diamond - Pittsburg',
    logo: `${url}/images/black-diamond/logo.svg`, // from api because is under generic site
    icon: `${url}/images/black-diamond/favicon.ico`, // from api is under generic site
    homeImages: [`${url}/images/black-diamond/home-image-1.svg`]
    // rotateSeconds: 30,
    // rotatePages: [
    //   {
    //     path: routePaths.public.home,
    //     duration: 60
    //   },
    //   {
    //     path: routePaths.public.graph,
    //     duration: 30
    //   },
    //   {
    //     path: `/howto/${routePaths.public.howto.sunSolarEnergy}`,
    //     duration: 12
    //   },
    //   {
    //     path: `/howto/${routePaths.public.howto.panelInverter}`,
    //     duration: 12
    //   },
    //   {
    //     path: `/howto/${routePaths.public.howto.inverterMeter}`,
    //     duration: 12
    //   },
    //   {
    //     path: `/howto/${routePaths.public.howto.meterGrid}`,
    //     duration: 12
    //   }
    // ]
  },
  {
    name: 'foothill',
    domain: 'https://foothilles-solardashboard.corbinbrooks.com',
    hostname: 'foothilles-solardashboard.corbinbrooks.com',
    siteId: 56216,
    lifetimeProductionUnit: 'gigawatt', //Options should be: kilowatt, megawatt, gigawatt
    wheelColor: {},
    meterColor: '#231F20',
    meterFontColor: '#fff',
    weatherIconColor: '#231F20',
    bottomCardsColor: '#231F20',
    bottomCardsIconColor: '#231F20',
    howToActiveLinkBgColor: '#231F20',
    howToActiveLinkBorderDisabled: true,
    sidebarLogoSize: '71px',
    title: 'Foothill Elementary School',
    tabTitle: 'Foothill - Pittsburg',
    logo: `${url}/images/foothill/logo.svg`,
    icon: `${url}/images/foothill/favicon.ico`,
    chartId: 15,
    showYearProduction: false,
    showChartLabels: true,
    showChartDots: false,
    chartCategories: [],
    chartLeyendAlignment: 'left', // left | center |right
    lineGraphWidth: 3,
    rotateSeconds: 30,
    rotatePages: [
      {
        path: routePaths.public.home,
        duration: 60
      },
      {
        path: routePaths.public.graph,
        duration: 30
      },
      {
        path: `/howto/${routePaths.public.howto.sunSolarEnergy}`,
        duration: 12
      },
      {
        path: `/howto/${routePaths.public.howto.panelInverter}`,
        duration: 12
      },
      {
        path: `/howto/${routePaths.public.howto.inverterMeter}`,
        duration: 12
      },
      {
        path: `/howto/${routePaths.public.howto.meterGrid}`,
        duration: 12
      }
    ],
    homeImages: [`${url}/images/foothill/home-image-1.svg`]
  },
  {
    name: 'heights',
    domain: 'https://heightses-solardashboard.corbinbrooks.com',
    hostname: 'heightses-solardashboard.corbinbrooks.com',
    siteId: 56217, // to be updated
    lifetimeProductionUnit: 'gigawatt', //Options should be: kilowatt, megawatt, gigawatt
    wheelColor: {},
    meterColor: '#17315D',
    meterFontColor: '#fff',
    weatherIconColor: '#17315D',
    bottomCardsColor: '#17315D',
    bottomCardsIconColor: '#17315D',
    howToActiveLinkBgColor: '#17315D',
    howToActiveLinkBorderDisabled: true,
    sidebarLogoSize: '120px',
    bottomInfo: ['size', 'since', 'lastUpdate'],
    generatingSinceDateFormat: 'll',
    lastUpdateDateFormat: 'h:mm A MMM DD, YY',
    fontFamily: 'Poppins',
    title: 'Heights Elementary School',
    tabTitle: 'Heights - Pittsburg',
    logo: `${url}/images/heights/logo.svg`,
    icon: `${url}/images/heights/favicon.ico`,
    chartId: 15,
    showYearProduction: false,
    showChartLabels: true,
    showChartDots: false,
    chartCategories: [],
    chartLeyendAlignment: 'left', // left | center |right
    lineGraphWidth: 3,
    rotateSeconds: 30,
    rotatePages: [
      {
        path: routePaths.public.home,
        duration: 60
      },
      {
        path: routePaths.public.graph,
        duration: 30
      },
      {
        path: `/howto/${routePaths.public.howto.sunSolarEnergy}`,
        duration: 12
      },
      {
        path: `/howto/${routePaths.public.howto.panelInverter}`,
        duration: 12
      },
      {
        path: `/howto/${routePaths.public.howto.inverterMeter}`,
        duration: 12
      },
      {
        path: `/howto/${routePaths.public.howto.meterGrid}`,
        duration: 12
      }
    ],
    homeImages: [`${url}/images/heights/home-image-1.svg`],
    position: 'right'
  },
  {
    name: 'highlands',
    domain: 'https://highlandses-solardashboard.corbinbrooks.com',
    hostname: 'highlandses-solardashboard.corbinbrooks.com',
    siteId: 56218, // to be updated
    lifetimeProductionUnit: 'gigawatt', //Options should be: kilowatt, megawatt, gigawatt
    wheelColor: {},
    meterColor: '#0D7F3F',
    meterFontColor: '#fff',
    weatherIconColor: '#0D7F3F',
    bottomCardsColor: '#0D7F3F',
    bottomCardsIconColor: '#0D7F3F',
    howToActiveLinkBgColor: '#0D7F3F',
    howToActiveLinkBorderDisabled: true,
    sidebarLogoSize: '120px',

    bottomInfo: ['size', 'since', 'lastUpdate'],
    generatingSinceDateFormat: 'll',
    //generatingSinceData: 'Dec 4, 2021',
    lastUpdateDateFormat: 'h:mm A MMM DD, YY',
    fontFamily: 'Poppins',

    title: 'Highlands Elementary School',
    tabTitle: 'Highlands - Pittsburg',
    logo: `${url}/images/highlands/logo.svg`,
    icon: `${url}/images/highlands/favicon.ico`,
    chartId: 15,
    showYearProduction: false,
    showChartLabels: true,
    showChartDots: false,
    chartCategories: [],
    chartLeyendAlignment: 'left', // left | center |right
    lineGraphWidth: 3,
    rotateSeconds: 30,
    rotatePages: [
      {
        path: routePaths.public.home,
        duration: 60
      },
      {
        path: routePaths.public.graph,
        duration: 30
      },
      {
        path: `/howto/${routePaths.public.howto.sunSolarEnergy}`,
        duration: 12
      },
      {
        path: `/howto/${routePaths.public.howto.panelInverter}`,
        duration: 12
      },
      {
        path: `/howto/${routePaths.public.howto.inverterMeter}`,
        duration: 12
      },
      {
        path: `/howto/${routePaths.public.howto.meterGrid}`,
        duration: 12
      }
    ],
    homeImages: [`${url}/images/highlands/home-image-1.svg`]
  },
  {
    name: 'rancho',
    domain: 'https://ranchomedanosjuniorhs-solardashboard.corbinbrooks.com',
    hostname: 'ranchomedanosjuniorhs-solardashboard.corbinbrooks.com',
    siteId: 56227,
    lifetimeProductionUnit: 'gigawatt', //Options should be: kilowatt, megawatt, gigawatt
    wheelColor: {},
    meterColor: '#237239',
    meterFontColor: '#fff',
    weatherIconColor: '#237239',
    bottomCardsColor: '#237239',
    bottomCardsIconColor: '#237239',
    howToActiveLinkBgColor: '#237239',
    howToActiveLinkBorderDisabled: true,
    sidebarLogoSize: '120px',
    chartId: 15,
    showChartLabels: true,
    showChartDots: false,
    chartLeyendAlignment: 'left',
    title: 'Rancho Medanos Junior High School',
    tabTitle: 'Rancho Medanos Junior High School - Pittsburg',
    logo: `${url}/images/rancho/logo.svg`,
    icon: `${url}/images/rancho/favicon.ico`,
    rotateSeconds: 30,
    rotatePages: [
      {
        path: routePaths.public.home,
        duration: 60
      },
      {
        path: routePaths.public.graph,
        duration: 30
      },
      {
        path: `/howto/${routePaths.public.howto.sunSolarEnergy}`,
        duration: 12
      },
      {
        path: `/howto/${routePaths.public.howto.panelInverter}`,
        duration: 12
      },
      {
        path: `/howto/${routePaths.public.howto.inverterMeter}`,
        duration: 12
      },
      {
        path: `/howto/${routePaths.public.howto.meterGrid}`,
        duration: 12
      }
    ],
    homeImages: [`${url}/images/rancho/home-image-1.svg`],
    position: 'bottom'
  },
  {
    name: 'hillview',
    domain: 'https://hillviewjuniorhs-solardashboard.corbinbrooks.com', // to be updated
    hostname: 'hillviewjuniorhs-solardashboard.corbinbrooks.com',
    siteId: 56219,
    lifetimeProductionUnit: 'gigawatt', //Options should be: kilowatt, megawatt, gigawatt
    wheelColor: {},
    meterColor: '#2E3091',
    meterFontColor: '#fff',
    weatherIconColor: '#2E3091',
    bottomCardsColor: '#2E3091',
    bottomCardsIconColor: '#2E3091',
    howToActiveLinkBgColor: '#2E3091',
    howToActiveLinkBorderDisabled: true,
    sidebarLogoSize: '120px',

    title: 'Hillview Junior High School',
    tabTitle: 'Hillview Junior High School - Pittsburg',
    logo: `${url}/images/hillview/logo.svg`,
    icon: `${url}/images/hillview/favicon.ico`,
    chartId: 15,
    showYearProduction: false,
    showChartLabels: true,
    showChartDots: false,
    chartCategories: [],
    chartLeyendAlignment: 'left', // left | center |right
    lineGraphWidth: 3,
    rotateSeconds: 30,
    rotatePages: [
      {
        path: routePaths.public.home,
        duration: 60
      },
      {
        path: routePaths.public.graph,
        duration: 30
      },
      {
        path: `/howto/${routePaths.public.howto.sunSolarEnergy}`,
        duration: 12
      },
      {
        path: `/howto/${routePaths.public.howto.panelInverter}`,
        duration: 12
      },
      {
        path: `/howto/${routePaths.public.howto.inverterMeter}`,
        duration: 12
      },
      {
        path: `/howto/${routePaths.public.howto.meterGrid}`,
        duration: 12
      }
    ],
    homeImages: [`${url}/images/hillview/home-image-1.svg`]
  },
  {
    name: 'martin-luther-king',
    domain: 'https://martinlutherkingjrjuniorhs-solardashboard.corbinbrooks.com',
    hostname: 'martinlutherkingjrjuniorhs-solardashboard.corbinbrooks.com',
    siteId: 56222,
    lifetimeProductionUnit: 'gigawatt', //Options should be: kilowatt, megawatt, gigawatt
    wheelColor: {},
    meterColor: '#603382',
    meterFontColor: '#fff',
    bottomCardsColor: '#603382',
    howToActiveLinkBgColor: '#603382',
    howToActiveLinkBorderDisabled: true,
    bottomCardsIconColor: '#603382',
    weatherIconColor: '#603382',
    chartId: 255,
    showYearProduction: false,
    showChartLabels: true,
    showChartDots: false,
    chartCategories: [],
    chartLeyendAlignment: 'left', // left | center |right
    lineGraphWidth: 3,
    title: 'Martin Luther King Jr. Junior High School',
    tabTitle: 'Martin Luther King Jr. Junior High School - Pittsburg',
    logo: `${url}/images/martin-luther-king/logo.svg`,
    icon: `${url}/images/martin-luther-king/favicon.png`,
    rotateSeconds: 30,
    rotatePages: [
      {
        path: routePaths.public.home,
        duration: 60
      },
      {
        path: routePaths.public.graph,
        duration: 30
      },
      {
        path: `/howto/${routePaths.public.howto.sunSolarEnergy}`,
        duration: 12
      },
      {
        path: `/howto/${routePaths.public.howto.panelInverter}`,
        duration: 12
      },
      {
        path: `/howto/${routePaths.public.howto.inverterMeter}`,
        duration: 12
      },
      {
        path: `/howto/${routePaths.public.howto.meterGrid}`,
        duration: 12
      }
    ],
    homeImages: [`${url}/images/martin-luther-king/home-image-1.svg`],
    position: 'right'
  },
  {
    name: 'los-medanos',
    domain: 'https://losmedanoses-solardashboard.corbinbrooks.com',
    hostname: 'losmedanoses-solardashboard.corbinbrooks.com',
    siteId: 56220, // to be updated
    lifetimeProductionUnit: 'gigawatt', //Options should be: kilowatt, megawatt, gigawatt
    wheelColor: {},
    meterColor: '#1E4693',
    meterFontColor: '#fff',
    weatherIconColor: '#1E4693',
    bottomCardsColor: '#1E4693',
    bottomCardsIconColor: '#1E4693',
    howToActiveLinkBgColor: '#1E4693',
    howToActiveLinkBorderDisabled: true,
    sidebarLogoSize: '120px',
    title: 'Los Medanos Elementary School',
    tabTitle: 'Los Medanos - Pittsburg',
    logo: `${url}/images/los-medanos/logo.svg`,
    icon: `${url}/images/los-medanos/favicon.ico`,
    chartId: 15,
    showYearProduction: false,
    showChartLabels: true,
    showChartDots: false,
    chartCategories: [],
    chartLeyendAlignment: 'left', // left | center |right
    lineGraphWidth: 3,
    rotateSeconds: 30,
    rotatePages: [
      {
        path: routePaths.public.home,
        duration: 60
      },
      {
        path: routePaths.public.graph,
        duration: 30
      },
      {
        path: `/howto/${routePaths.public.howto.sunSolarEnergy}`,
        duration: 12
      },
      {
        path: `/howto/${routePaths.public.howto.panelInverter}`,
        duration: 12
      },
      {
        path: `/howto/${routePaths.public.howto.inverterMeter}`,
        duration: 12
      },
      {
        path: `/howto/${routePaths.public.howto.meterGrid}`,
        duration: 12
      }
    ],
    homeImages: [`${url}/images/los-medanos/home-image.svg`, `${url}/images/los-medanos/home-image2.svg`],
    position: 'center'
  },
  {
    name: 'pit-compilation',
    domain: 'https://pittsburghs-solardashboard.corbinbrooks.com', // to be updated
    hostname: 'pittsburghs-solardashboard.corbinbrooks.com', // to be updated

    composedSite: true,
    subSites: {
      idList: [56224, 56225, 56226],
      sumValues: true,
      useHardwareId: false
    },
    siteId: 56224, // to be updated
    lifetimeProductionUnit: 'gigawatt', //Options should be: kilowatt, megawatt, gigawatt
    wheelColor: {},
    meterColor: '#F26722',
    meterFontColor: '#fff',
    weatherIconColor: '#F26722',
    bottomCardsColor: '#F26722',
    bottomCardsIconColor: '#F26722',
    howToActiveLinkBgColor: '#F26722',
    howToActiveLinkBorderDisabled: true,
    sidebarLogoSize: '120px',
    title: 'Pittburg Compilation High School',
    tabTitle: 'Compilation - Pittsburg',
    logo: `${url}/images/pit-compilation/logo.svg`,
    icon: `${url}/images/pit-compilation/favicon.ico`,
    chartId: 15,
    showYearProduction: false,
    showChartLabels: true,
    showChartDots: false,
    chartCategories: [],
    chartLeyendAlignment: 'left', // left | center |right
    lineGraphWidth: 3,
    rotateSeconds: 30,
    rotatePages: [
      {
        path: routePaths.public.home,
        duration: 60
      },
      {
        path: routePaths.public.graph,
        duration: 30
      },
      {
        path: `/howto/${routePaths.public.howto.sunSolarEnergy}`,
        duration: 12
      },
      {
        path: `/howto/${routePaths.public.howto.panelInverter}`,
        duration: 12
      },
      {
        path: `/howto/${routePaths.public.howto.inverterMeter}`,
        duration: 12
      },
      {
        path: `/howto/${routePaths.public.howto.meterGrid}`,
        duration: 12
      }
    ],
    homeImages: [
      `${url}/images/pit-compilation/home-image-1.svg`,
      `${url}/images/pit-compilation/home-image-2.svg`,
      `${url}/images/pit-compilation/home-image-3.svg`,
      `${url}/images/pit-compilation/home-image-4.svg`
    ]
  },
  {
    name: 'marina',
    domain: 'https://marinavistaes-solardashboard.corbinbrooks.com',
    hostname: 'marinavistaes-solardashboard.corbinbrooks.com',
    siteId: 56221,
    lifetimeProductionUnit: 'gigawatt', //Options should be: kilowatt, megawatt, gigawatt
    wheelColor: {},
    meterColor: '#005A9C',
    meterFontColor: '#fff',
    weatherIconColor: '#005A9C',
    bottomCardsColor: '#005A9C',
    bottomCardsIconColor: '#005A9C',
    howToActiveLinkBgColor: '#005A9C',
    howToActiveLinkBorderDisabled: true,
    sidebarLogoSize: '120px',

    bottomInfo: ['size', 'since', 'lastUpdate'],
    generatingSinceDateFormat: 'll',
    lastUpdateDateFormat: 'h:mm A MMM DD, YY',
    fontFamily: 'Poppins',

    title: 'Marina Vista ES',
    tabTitle: 'Marina - Pittsburg',
    logo: `${url}/images/marina/logo.png`,
    icon: `${url}/images/marina/favicon.ico`,
    chartId: 15,
    showYearProduction: false,
    showChartLabels: true,
    showChartDots: false,
    chartCategories: [],
    chartLeyendAlignment: 'left', // left | center |right
    lineGraphWidth: 3,
    cardColor: '#005A9C',
    iconColor: '#809E6A',
    rotateSeconds: 30,
    rotatePages: [
      {
        path: routePaths.public.home,
        duration: 60
      },
      {
        path: routePaths.public.graph,
        duration: 30
      },
      {
        path: `/howto/${routePaths.public.howto.sunSolarEnergy}`,
        duration: 12
      },
      {
        path: `/howto/${routePaths.public.howto.panelInverter}`,
        duration: 12
      },
      {
        path: `/howto/${routePaths.public.howto.inverterMeter}`,
        duration: 12
      },
      {
        path: `/howto/${routePaths.public.howto.meterGrid}`,
        duration: 12
      }
    ],
    homeImages: [`${url}/images/marina/home-image-1.svg`],
    position: 'center'
  },
  {
    name: 'pit-unified',
    domain: 'https://pittsburgusd-solardashboard.corbinbrooks.com', // to be updated
    hostname: 'pittsburgusd-solardashboard.corbinbrooks.com', // to be updated
    composedSite: true,
    subSites: {
      idList: [
        56214, 56215, 56216, 56217, 56218, 56219, 56220, 56221, 56222, 56223, 56224, 56225, 56226, 56227, 56228, 56229,
        56230, 56231
      ],
      sumValues: true,
      useHardwareId: true
    },
    siteId: 56215, // to be updated
    lifetimeProductionUnit: 'gigawatt', //Options should be: kilowatt, megawatt, gigawatt
    wheelColor: {},
    meterColor: '#F26722',
    meterFontColor: '#fff',
    weatherIconColor: '#F26722',
    bottomCardsColor: '#F26722',
    bottomCardsIconColor: '#F26722',
    howToActiveLinkBgColor: '#F26722',
    howToActiveLinkBorderDisabled: true,
    sidebarLogoSize: '120px',
    title: 'Pittsburg Unified School District',
    tabTitle: 'Unified School District - Pittsburg',
    logo: `${url}/images/pit-unified/logo.svg`, // from api because is under generic site
    icon: `${url}/images/pit-unified/favicon.ico`, // from api is under generic site
    chartId: 15,
    arrHardwareId:
      '285042,285050,285061,285068,285147,285153,285163,285174,287178,291851,291852,291864,289611,285178,285137,285181,285188,285194,285212,285221,289310',
    showYearProduction: false,
    showChartLabels: true,
    showChartDots: false,
    chartCategories: [],
    chartLeyendAlignment: 'left', // left | center |right
    lineGraphWidth: 3,
    rotateSeconds: 30,
    rotatePages: [
      {
        path: routePaths.public.home,
        duration: 60
      },
      {
        path: routePaths.public.graph,
        duration: 30
      },
      {
        path: `/howto/${routePaths.public.howto.sunSolarEnergy}`,
        duration: 12
      },
      {
        path: `/howto/${routePaths.public.howto.panelInverter}`,
        duration: 12
      },
      {
        path: `/howto/${routePaths.public.howto.inverterMeter}`,
        duration: 12
      },
      {
        path: `/howto/${routePaths.public.howto.meterGrid}`,
        duration: 12
      }
    ],
    homeImages: _.sampleSize(
      [
        `${url}/images/black-diamond/home-image-1.svg`,
        `${url}/images/foothill/home-image-1.svg`,
        `${url}/images/heights/home-image-1.svg`,
        `${url}/images/highlands/home-image-1.svg`,
        `${url}/images/rancho/home-image-1.svg`,
        `${url}/images/hillview/home-image-1.svg`,
        `${url}/images/martin-luther-king/home-image-1.svg`,
        `${url}/images/los-medanos/home-image.svg`,
        `${url}/images/los-medanos/home-image2.svg`,
        `${url}/images/pit-adult/home-image.png`,
        `${url}/images/marina/home-image-1.svg`,
        `${url}/images/parkside/home-image-1.svg`,
        `${url}/images/stoneman/home-image-1.svg`,
        `${url}/images/willow/home-image-1.svg`,
        `${url}/images/willow/home-image-2.svg`
      ],
      6
    )
  },
  {
    name: 'parkside',
    domain: 'https://parksidees-solardashboard.corbinbrooks.com',
    hostname: 'parksidees-solardashboard.corbinbrooks.com',
    siteId: 56223,
    weatherAltId: 56221,
    lifetimeProductionUnit: 'gigawatt', //Options should be: kilowatt, megawatt, gigawatt
    wheelColor: {},
    meterColor: '#005A9C',
    meterFontColor: '#fff',
    weatherIconColor: '#005A9C',
    bottomCardsColor: '#005A9C',
    bottomCardsIconColor: '#005A9C',
    howToActiveLinkBgColor: '#005A9C',
    howToActiveLinkBorderDisabled: true,
    sidebarLogoSize: '120px',
    title: 'Parkside Elementary School',
    tabTitle: 'Parkside Elementary School - Pittsburg',
    logo: `${url}/images/parkside/logo.svg`,
    icon: `${url}/images/parkside/favicon.ico`,
    chartId: 15,
    showYearProduction: false,
    showChartLabels: true,
    showChartDots: false,
    chartCategories: [],
    chartLeyendAlignment: 'left', // left | center |right
    lineGraphWidth: 3,
    rotateSeconds: 30,
    rotatePages: [
      {
        path: routePaths.public.home,
        duration: 60
      },
      {
        path: routePaths.public.graph,
        duration: 30
      },
      {
        path: `/howto/${routePaths.public.howto.sunSolarEnergy}`,
        duration: 12
      },
      {
        path: `/howto/${routePaths.public.howto.panelInverter}`,
        duration: 12
      },
      {
        path: `/howto/${routePaths.public.howto.inverterMeter}`,
        duration: 12
      },
      {
        path: `/howto/${routePaths.public.howto.meterGrid}`,
        duration: 12
      }
    ],
    homeImages: [`${url}/images/parkside/home-image-1.svg`]
  },
  {
    name: 'stoneman',
    domain: 'https://stonemanes-solardashboard.corbinbrooks.com', // to be updated
    hostname: 'stonemanes-solardashboard.corbinbrooks.com',
    siteId: 56228,
    lifetimeProductionUnit: 'gigawatt', //Options should be: kilowatt, megawatt, gigawatt
    wheelColor: {},
    meterColor: '#00395A',
    meterFontColor: '#fff',
    weatherIconColor: '#00395A',
    bottomCardsColor: '#00395A',
    bottomCardsIconColor: '#00395A',
    howToActiveLinkBgColor: '#00395A',
    howToActiveLinkBorderDisabled: true,
    sidebarLogoSize: '120px',

    title: 'Stoneman Elementary School',
    tabTitle: 'Stoneman ES - Pittsburg',
    logo: `${url}/images/stoneman/logo.svg`,
    icon: `${url}/images/stoneman/favicon.ico`,
    chartId: 15,
    showYearProduction: false,
    showChartLabels: true,
    showChartDots: false,
    chartCategories: [],
    chartLeyendAlignment: 'left', // left | center |right
    lineGraphWidth: 3,
    rotateSeconds: 30,
    rotatePages: [
      {
        path: routePaths.public.home,
        duration: 60
      },
      {
        path: routePaths.public.graph,
        duration: 30
      },
      {
        path: `/howto/${routePaths.public.howto.sunSolarEnergy}`,
        duration: 12
      },
      {
        path: `/howto/${routePaths.public.howto.panelInverter}`,
        duration: 12
      },
      {
        path: `/howto/${routePaths.public.howto.inverterMeter}`,
        duration: 12
      },
      {
        path: `/howto/${routePaths.public.howto.meterGrid}`,
        duration: 12
      }
    ],
    homeImages: [`${url}/images/stoneman/home-image-1.svg`]
  },
  {
    name: 'willow',
    domain: 'https://willowcovees-solardashboard.corbinbrooks.com',
    hostname: 'willowcovees-solardashboard.corbinbrooks.com',
    siteId: 56229,
    lifetimeProductionUnit: 'gigawatt', //Options should be: kilowatt, megawatt, gigawatt
    wheelColor: {},
    meterColor: '#BA2409',
    meterFontColor: '#fff',
    weatherIconColor: '#BA2409',
    bottomCardsColor: '#BA2409',
    bottomCardsIconColor: '#BA2409',
    howToActiveLinkBgColor: '#BA2409',
    howToActiveLinkBorderDisabled: true,
    sidebarLogoSize: '120px',

    title: 'Willow Cove Elementary School',
    tabTitle: 'Willow Cove ES - Pittsburg',
    logo: `${url}/images/willow/logo.svg`,
    icon: `${url}/images/willow/favicon.ico`,
    chartId: 15,
    showYearProduction: false,
    showChartLabels: true,
    showChartDots: false,
    chartCategories: [],
    chartLeyendAlignment: 'left', // left | center |right
    lineGraphWidth: 3,
    rotateSeconds: 30,
    rotatePages: [
      {
        path: routePaths.public.home,
        duration: 60
      },
      {
        path: routePaths.public.graph,
        duration: 30
      },
      {
        path: `/howto/${routePaths.public.howto.sunSolarEnergy}`,
        duration: 12
      },
      {
        path: `/howto/${routePaths.public.howto.panelInverter}`,
        duration: 12
      },
      {
        path: `/howto/${routePaths.public.howto.inverterMeter}`,
        duration: 12
      },
      {
        path: `/howto/${routePaths.public.howto.meterGrid}`,
        duration: 12
      }
    ],
    homeImages: [`${url}/images/willow/home-image-1.svg`, `${url}/images/willow/home-image-2.svg`]
  },
  {
    name: 'pit-adult',
    domain: 'https://pittsburgadultec-solardashboard.corbinbrooks.com',
    hostname: 'pittsburgadultec-solardashboard.corbinbrooks.com',
    siteId: 56214,
    lifetimeProductionUnit: 'gigawatt', //Options should be: kilowatt, megawatt, gigawatt
    wheelColor: {},
    meterColor: '#7C1337',
    meterFontColor: '#fff',
    weatherIconColor: '#7C1337',
    bottomCardsColor: '#7C1337',
    bottomCardsIconColor: '#7C1337',
    howToActiveLinkBgColor: '#7C1337',
    howToActiveLinkBorderDisabled: true,
    sidebarLogoSize: '120px',
    chartId: 15,
    showChartLabels: true,
    showChartDots: false,
    chartLeyendAlignment: 'left',
    title: 'Pittsburg Adult Education Center',
    tabTitle: 'Adult Education Center ES - Pittsburg',
    logo: `${url}/images/pit-adult/logo.svg`,
    icon: `${url}/images/pit-adult/favicon.ico`,
    rotateSeconds: 30,
    rotatePages: [
      {
        path: routePaths.public.home,
        duration: 60
      },
      {
        path: routePaths.public.graph,
        duration: 30
      },
      {
        path: `/howto/${routePaths.public.howto.sunSolarEnergy}`,
        duration: 12
      },
      {
        path: `/howto/${routePaths.public.howto.panelInverter}`,
        duration: 12
      },
      {
        path: `/howto/${routePaths.public.howto.inverterMeter}`,
        duration: 12
      },
      {
        path: `/howto/${routePaths.public.howto.meterGrid}`,
        duration: 12
      }
    ],
    homeImages: [`${url}/images/pit-adult/home-image.png`]
  },
  {
    name: 'creighton',
    domain: 'https://creightonscorneres-solardashboard.corbinbrooks.com',
    hostname: 'creightonscorneres-solardashboard.corbinbrooks.com',
    siteId: 61289,
    smaApi: {
      plantId: 7852979,
      deviceId: 7852992,
      deviceIds: {
        RGM_SP: 10500731,
        RGM_NC: 11857603
      }
    },
    location: {
      longitude: -77.52817,
      latitude: 38.97189
    },
    lifetimeProductionUnit: 'gigawatt', //Options should be: kilowatt, megawatt, gigawatt
    wheelColor: { start: '#FAFAFA', end: '#0A3764' },
    meterColor: '#23772D',
    meterFontColor: '#fff',
    bottomCardsColor: '#003E7E',
    howToActiveLinkBgColor: '#334D6E',
    howToActiveLinkBorderDisabled: false,
    bottomCardsIconColor: '#003E7E',
    weatherIconColor: '#003E7E',
    chartId: 15,
    showYearProduction: false,
    showChartLabels: true,
    showChartDots: false,
    chartCategories: ['RGM1-SP', 'RGM2-NC'],
    chartLeyendAlignment: 'left', // left | center |right
    lineGraphWidth: 3,
    sideBar: {
      infoFontSize: 14,
      climateEnabled: true,
      climate: {
        title: 'Current Weather',
        iconPosition: 'top'
      },
      showForecast: false
    },
    graphPageTitle: 'Creighton’s Corner Elementary School',
    hardwareIdWeeklySystem: [447172, 447171],
    showForecast: true,
    title: 'Creighton’s Corner Elementary School',
    tabTitle: 'Creighton’s Corner - Solardashboard',
    logo: `${url}/images/creighton/creighton-logo.png`, // from api because is under generic site
    icon: `${url}/images/creighton/favicon.ico`, // from api is under generic site
    bottomCardConfig: {
      images: {
        card4: `${url}/images/creighton/creighton-bottom-card.svg`
      },
      topTitle: 'Total energy generated equals',
      hideTopIcon: true,
      iconsOverride: [
        {
          type: EQUIVALENCES.CARBON,
          iconName: getCardIconName('Carbon Dioxide')
        }
      ]
    },
    bottomImageGraphPage: `${url}/images/creighton/creighton-bottom-graph-image.svg`,
    renameChartLabels: true,
    rotateSeconds: 10,
    howToPages: [
      `/howto/${routePaths.public.howto.sunSolarEnergy}`,
      `/howto/${routePaths.public.howto.panelInverter}`,
      `/howto/${routePaths.public.howto.meterGrid}`,
      `/howto/${routePaths.public.howto.summary}`
    ]
  },
  {
    name: 'catoctin',
    domain: 'https://catoctines-solardashboard.corbinbrooks.com',
    hostname: 'catoctines-solardashboard.corbinbrooks.com',
    siteId: 61287,
    smaApi: {
      plantId: 7562468,
      deviceId: 7562474,
      deviceIds: {
        RGM_SP: 10502789,
        RGM_NC: 10502790
      }
    },
    location: {
      longitude: -77.57493,
      latitude: 39.1091
    },
    lifetimeProductionUnit: 'gigawatt', //Options should be: kilowatt, megawatt, gigawatt
    wheelColor: { start: '#FAFAFA', end: '#95272B' },
    meterColor: '#95272B',
    meterFontColor: '#fff',
    bottomCardsColor: '#95272B',
    howToActiveLinkBgColor: '#95272B',
    howToActiveLinkBorderDisabled: false,
    bottomCardsIconColor: '#95272B',
    weatherIconColor: '#95272B',
    chartId: 15,
    showYearProduction: false,
    showChartLabels: true,
    showChartDots: false,
    chartCategories: ['RGM1-SP', 'RGM2-NC'],
    chartLeyendAlignment: 'left', // left | center |right
    lineGraphWidth: 3,
    sideBar: {
      infoFontSize: 14,
      climateEnabled: true,
      climate: {
        title: 'Current Weather',
        iconPosition: 'top'
      },
      showForecast: false
    },
    graphPageTitle: 'Catoctin Elementary School',
    hardwareIdWeeklySystem: [447169, 447170],
    showForecast: true,
    title: 'Catoctin Elementary School',
    tabTitle: 'Catoctin Elementary School - Solardashboard',
    logo: `${url}/images/catoctin/catoctin-logo.svg`, // from api because is under generic site
    icon: `${url}/images/catoctin/favicon.ico`, // from api is under generic site
    bottomCardConfig: {
      images: {
        card4: `${url}/images/catoctin/catoctin-bottom-card.svg`
      },
      topTitle: 'Total energy generated equals',
      hideTopIcon: true,
      iconsOverride: [
        {
          type: EQUIVALENCES.CARBON,
          iconName: getCardIconName('Carbon Dioxide')
        }
      ]
    },
    renameChartLabels: true,
    rotateSeconds: 10,
    howToPages: [
      `/howto/${routePaths.public.howto.sunSolarEnergy}`,
      `/howto/${routePaths.public.howto.panelInverter}`,
      `/howto/${routePaths.public.howto.meterGrid}`,
      `/howto/${routePaths.public.howto.summary}`
    ]
  },
  {
    name: 'cardinal',
    domain: 'https://cardinalridgees-solardashboard.corbinbrooks.com',
    hostname: 'cardinalridgees-solardashboard.corbinbrooks.com',
    siteId: 65837,
    smaApi: {
      plantId: 10721842,
      deviceId: 10721848,
      deviceIds: {
        RGM_SP: 10748214,
        RGM_NC: 10748213
      }
    },
    location: {
      longitude: -77.517275,
      latitude: 38.894782
    },
    lifetimeProductionUnit: 'gigawatt', //Options should be: kilowatt, megawatt, gigawatt
    wheelColor: { start: '#FAFAFA', end: '#95272B' },
    meterColor: '#95272B',
    meterFontColor: '#fff',
    bottomCardsColor: '#95272B',
    howToActiveLinkBgColor: '#95272B',
    howToActiveLinkBorderDisabled: false,
    bottomCardsIconColor: '#95272B',
    weatherIconColor: '#95272B',
    chartId: 15,
    showYearProduction: false,
    showChartLabels: true,
    showChartDots: false,
    chartCategories: ['RGM1-SP', 'RGM2-NC'],
    chartLeyendAlignment: 'left', // left | center |right
    lineGraphWidth: 3,
    sideBar: {
      infoFontSize: 14,
      climateEnabled: true,
      climate: {
        title: 'Current Weather',
        iconPosition: 'top'
      },
      showForecast: false
    },
    graphPageTitle: 'Cardinal Ridge Elementary School',
    hardwareIdWeeklySystem: [463011, 463012],
    showForecast: true,
    title: 'Cardinal Ridge Elementary School',
    tabTitle: 'Cardinal Ridge Elementary School - Solardashboard',
    logo: `${url}/images/cardinal/cardinal-logo.svg`, // from api because is under generic site
    icon: `${url}/images/cardinal/favicon.ico`, // from api is under generic site
    bottomCardConfig: {
      images: {
        card4: `${url}/images/cardinal/cardinal-bottom-card.svg`
      },
      topTitle: 'Total energy generated equals',
      hideTopIcon: true,
      iconsOverride: [
        {
          type: EQUIVALENCES.CARBON,
          iconName: getCardIconName('Carbon Dioxide')
        }
      ]
    },
    rotateSeconds: 10,
    howToPages: [
      `/howto/${routePaths.public.howto.sunSolarEnergy}`,
      `/howto/${routePaths.public.howto.panelInverter}`,
      `/howto/${routePaths.public.howto.meterGrid}`,
      `/howto/${routePaths.public.howto.summary}`
    ]
  },
  {
    name: 'steauart',
    domain: 'https://steuartwelleres-solardashboard.corbinbrooks.com',
    hostname: 'steuartwelleres-solardashboard.corbinbrooks.com',
    siteId: 61288,
    smaApi: {
      plantId: 7562187,
      deviceId: 7562202,
      deviceIds: {
        RGM_SP: 11857459,
        RGM_NC: 11857458
      }
    },
    location: {
      longitude: -77.4549,
      latitude: 39.04311
    },
    lifetimeProductionUnit: 'gigawatt', //Options should be: kilowatt, megawatt, gigawatt
    wheelColor: { start: '#FAFAFA', end: '#0062A6' },
    meterColor: '#0062A6',
    meterFontColor: '#fff',
    bottomCardsColor: '#0062A6',
    howToActiveLinkBgColor: '#0062A6',
    howToActiveLinkBorderDisabled: false,
    bottomCardsIconColor: '#0062A6',
    weatherIconColor: '#0062A6',
    chartId: 15,
    showYearProduction: false,
    showChartLabels: true,
    showChartDots: false,
    chartCategories: ['RGM1-SP', 'RGM2-NC'],
    chartLeyendAlignment: 'left', // left | center |right
    lineGraphWidth: 3,
    sideBar: {
      infoFontSize: 14,
      climateEnabled: true,
      climate: {
        title: 'Current Weather',
        iconPosition: 'top'
      },
      showForecast: false
    },
    graphPageTitle: 'Steuart Weller Elementary School',
    hardwareIdWeeklySystem: [447179, 447180],
    showForecast: true,
    title: 'Steuart Weller Elementary School',
    tabTitle: 'Steuart Weller Elementary School - Solardashboard',
    logo: `${url}/images/steuart/steuart-logo.svg`, // from api because is under generic site
    icon: `${url}/images/steuart/favicon.ico`, // from api is under generic site
    bottomCardConfig: {
      images: {
        card4: `${url}/images/steuart/steuart-bottom-card.svg`
      },
      topTitle: 'Total energy generated equals',
      hideTopIcon: true,
      iconsOverride: [
        {
          type: EQUIVALENCES.CARBON,
          iconName: getCardIconName('Carbon Dioxide')
        }
      ]
    },
    bottomImageGraphPage: `${url}/images/steuart/steuart-bottom-graph-image.svg`,
    renameChartLabels: true,
    rotateSeconds: 10,
    howToPages: [
      `/howto/${routePaths.public.howto.sunSolarEnergy}`,
      `/howto/${routePaths.public.howto.panelInverter}`,
      `/howto/${routePaths.public.howto.meterGrid}`,
      `/howto/${routePaths.public.howto.summary}`
    ]
  },
  {
    name: 'hovatter',
    domain: 'https://hovatteres-solardashboard.corbinbrooks.com',
    hostname: 'hovatteres-solardashboard.corbinbrooks.com',
    siteId: 65834,
    smaApi: {
      plantId: 10697896,
      deviceId: 10697904,
      deviceIds: {
        RGM_SP: 10792660,
        RGM_NC: 10792659
      }
    },
    location: {
      longitude: -77.23576,
      latitude: 38.87662
    },
    lifetimeProductionUnit: 'kilowatt', //Options should be: kilowatt, megawatt, gigawatt
    wheelColor: { start: '#FAFAFA', end: '#163959' },
    meterColor: '#163959',
    meterFontColor: '#fff',
    bottomCardsColor: '#163959',
    howToActiveLinkBgColor: '#163959',
    howToActiveLinkBorderDisabled: false,
    bottomCardsIconColor: '#163959',
    weatherIconColor: '#163959',
    chartId: 15,
    showYearProduction: false,
    showChartLabels: true,
    showChartDots: false,
    // chartCategories: ['RGM1-SP', 'RGM2-NC'],
    chartLeyendAlignment: 'left', // left | center |right
    lineGraphWidth: 3,
    sideBar: {
      infoFontSize: 14,
      climateEnabled: true,
      climate: {
        title: 'Current Weather',
        iconPosition: 'top'
      },
      showForecast: false
    },
    graphPageTitle: 'Hovatter Elementary School',
    showForecast: true,
    title: 'Hovatter Elementary School',
    tabTitle: 'Hovatter Elementary School - Solardashboard',
    logo: `${url}/images/hovatter/hovatter-logo.svg`, // from api because is under generic site
    icon: `${url}/images/hovatter/favicon.ico`, // from api is under generic site
    bottomCardConfig: {
      images: {
        card4: `${url}/images/hovatter/hovatter-building.svg`
      },
      topTitle: 'Total energy generated equals',
      hideTopIcon: true,
      iconsOverride: [
        {
          type: EQUIVALENCES.CARBON,
          iconName: getCardIconName('Carbon Dioxide')
        }
      ]
    },
    bottomImageGraphPage: `${url}/images/hovatter/hovatter-bottom-graph-image.svg`,
    rotateSeconds: 10,
    howToPages: [
      `/howto/${routePaths.public.howto.sunSolarEnergy}`,
      `/howto/${routePaths.public.howto.panelInverter}`,
      `/howto/${routePaths.public.howto.meterGrid}`,
      `/howto/${routePaths.public.howto.summary}`
    ]
  },
  {
    name: 'moorefield',
    domain: 'https://moorefieldstationes-solardashboard.corbinbrooks.com',
    hostname: 'moorefieldstationes-solardashboard.corbinbrooks.com',
    siteId: 61284,
    smaApi: {
      plantId: 7657294,
      deviceId: 7657304,
      deviceIds: {
        RGM_SP: 10493350,
        RGM_NC: 10493351
      }
    },
    location: {
      longitude: -77.50595,
      latitude: 38.9995
    },
    lifetimeProductionUnit: 'kilowatt', //Options should be: kilowatt, megawatt, gigawatt
    wheelColor: { start: '#FAFAFA', end: '#6A0000' },
    meterColor: '#6A0000',
    meterFontColor: '#fff',
    bottomCardsColor: '#6A0000',
    howToActiveLinkBgColor: '#6A0000',
    howToActiveLinkBorderDisabled: false,
    bottomCardsIconColor: '#6A0000',
    weatherIconColor: '#6A0000',
    chartId: 15,
    showYearProduction: false,
    showChartLabels: true,
    showChartDots: false,
    chartCategories: ['RGM1-SP', 'RGM2-NC'],
    chartLeyendAlignment: 'left', // left | center |right
    lineGraphWidth: 3,
    sideBar: {
      infoFontSize: 14,
      climateEnabled: true,
      climate: {
        title: 'Current Weather',
        iconPosition: 'top'
      },
      showForecast: false
    },
    graphPageTitle: 'Moorefield Station Elementary School',
    hardwareIdWeeklySystem: [447177, 447178],
    showForecast: true,
    title: 'Moorefield Station Elementary School',
    tabTitle: 'Moorefield Station - Solardashboard',
    logo: `${url}/images/moorefield/moorefield-logo.svg`, // from api because is under generic site
    icon: `${url}/images/moorefield/favicon.ico`, // from api is under generic site
    bottomCardConfig: {
      images: {
        card4: `${url}/images/moorefield/moorefield-bottom-card.svg`
      },
      topTitle: 'Total energy generated equals',
      hideTopIcon: true,
      iconsOverride: [
        {
          type: EQUIVALENCES.CARBON,
          iconName: getCardIconName('Carbon Dioxide')
        }
      ]
    },
    bottomImageGraphPage: `${url}/images/moorefield/moorefield-bottom-graph-image.svg`,
    renameChartLabels: true,
    rotateSeconds: 10,
    howToPages: [
      `/howto/${routePaths.public.howto.sunSolarEnergy}`,
      `/howto/${routePaths.public.howto.panelInverter}`,
      `/howto/${routePaths.public.howto.meterGrid}`,
      `/howto/${routePaths.public.howto.summary}`
    ]
  },
  {
    name: 'lucketts',
    domain: 'https://luckettses-solardashboard.corbinbrooks.com',
    hostname: 'luckettses-solardashboard.corbinbrooks.com',
    siteId: 61286,
    smaApi: {
      plantId: 7657096,
      deviceId: 7657104,
      deviceIds: {
        RGM_SP: 10761902,
        RGM_NC: 10492542
      }
    },
    location: {
      longitude: -77.53757,
      latitude: 39.1132
    },
    lifetimeProductionUnit: 'kilowatt', //Options should be: kilowatt, megawatt, gigawatt
    wheelColor: { start: '#FAFAFA', end: '#01329B' },
    meterColor: '#01329B',
    meterFontColor: '#fff',
    bottomCardsColor: '#01329B',
    howToActiveLinkBgColor: '#01329B',
    howToActiveLinkBorderDisabled: false,
    bottomCardsIconColor: '#01329B',
    weatherIconColor: '#01329B',
    chartId: 15,
    showYearProduction: false,
    showChartLabels: true,
    showChartDots: false,
    chartCategories: ['RGM1-SP', 'RGM2-NC'],
    chartLeyendAlignment: 'left', // left | center |right
    lineGraphWidth: 3,
    sideBar: {
      infoFontSize: 14,
      climateEnabled: true,
      climate: {
        title: 'Current Weather',
        iconPosition: 'top'
      },
      showForecast: false
    },
    graphPageTitle: 'Lucketts Elementary School',
    hardwareIdWeeklySystem: [447175, 447176],
    showForecast: true,
    title: 'Lucketts Elementary School',
    tabTitle: 'Lucketts Elementary School - Solardashboard',
    logo: `${url}/images/lucketts/lucketts-logo.PNG`,
    icon: `${url}/images/lucketts/favicon.ico`,
    bottomCardConfig: {
      images: {
        card4: `${url}/images/lucketts/home-image.png`
      },
      topTitle: 'Total energy generated equals',
      hideTopIcon: true,
      iconsOverride: [
        {
          type: EQUIVALENCES.CARBON,
          iconName: getCardIconName('Carbon Dioxide')
        }
      ]
    },
    bottomImageGraphPage: `${url}/images/lucketts/bottom-graph-image.svg`,
    renameChartLabels: true,
    rotateSeconds: 10,
    howToPages: [
      `/howto/${routePaths.public.howto.sunSolarEnergy}`,
      `/howto/${routePaths.public.howto.panelInverter}`,
      `/howto/${routePaths.public.howto.meterGrid}`,
      `/howto/${routePaths.public.howto.summary}`
    ]
  },
  {
    name: 'lockheed-martin',
    domain: 'https://lockheed-martin.corbinbrooks.com',
    hostname: 'lockheed-martin.corbinbrooks.com',
    siteId: 60717,
    installDateSelected: '2021-09-16T00:00:00-04:00',
    lifetimeProductionUnit: 'kilowatt', //Options should be: kilowatt, megawatt, gigawatt
    wheelColor: {},
    meterColor: '#003478',
    meterFontColor: '#fff',
    bottomCardsColor: '#003478',
    howToActiveLinkBgColor: '#01329B',
    howToActiveLinkBorderDisabled: false,
    bottomCardsIconColor: '#809E6A',
    showMonthValues: true,
    showTotalValues: false,
    showOnlyYearValues: true,
    weatherIconColor: '#003478',
    chartId: 15,
    showYearProduction: false,
    showChartLabels: true,
    showChartDots: false,
    chartLeyendAlignment: 'left', // left | center |right
    lineGraphWidth: 4,
    barGraphSize: 65,
    showForecast: true,
    systemDataColor: '#003478',
    tabTitle: 'Lockheed Martin - Solardashboard',
    logo: `${url}/images/lockheed/LM-logo.svg`,
    icon: `${url}/images/lockheed/favicon.ico`,
    siteOverviewImage: `${url}/images/lockheed/lockheed-overview.svg`,
    bottomCardConfig: {
      hideTopIcon: true
    },
    graphPageTitle: 'Solar Array Dashboard | Annual Production Summary',
    rotateSeconds: 10,
    rotatePages: [
      {
        path: routePaths.public.home,
        duration: 10
      },
      {
        path: routePaths.public.dashboard,
        duration: 10
      },
      {
        path: routePaths.public.graph,
        duration: 10
      }
    ]
  },
  {
    name: 'waateekaa',
    // domain: 'https://luckettses-solardashboard.corbinbrooks.com',
    // hostname: 'luckettses-solardashboard.corbinbrooks.com',
    siteId: 61286,
    lifetimeProductionUnit: 'kilowatt', //Options should be: kilowatt, megawatt, gigawatt
    wheelColor: { start: '#FAFAFA', end: '#57727F', piechart: '#EA4225' },
    meterColor: '#57727F',
    meterFontColor: '#fff',
    bottomCardTitleStyles: {
      fontColor: '#000000',
      fontSize: '18px'
    },
    bottomCardsColor: '#57727F',
    howToActiveLinkBgColor: '#EA4225',
    howToActiveLinkBorderDisabled: false,
    bottomCardsIconColor: '#EA4225',
    weatherIconColor: '#EA4225',
    chartId: 15,
    showYearProduction: false,
    showChartLabels: true,
    showChartDots: false,
    // chartCategories: ['RGM1-SP', 'RGM2-NC'],
    chartLeyendAlignment: 'left', // left | center |right
    lineGraphWidth: 3,
    sideBar: {
      infoFontSize: 14,
      climateEnabled: true,
      climate: {
        title: 'Current Weather',
        iconPosition: 'top'
      },
      showForecast: false
    },
    graphPageTitle: 'WaaTeeKaa Monitoring Dashboard',
    chartFilterDefaultRange: {
      spanData: '3day',
      binData: 'Bin1Hour'
    },
    hardwareIdWeeklySystem: [447175, 447176],
    showForecast: true,
    renameChartLabels: true,
    hideMeteredProductionText: true,
    chartYaxisLabel: 'Kilowatt Hours',
    chartYaxisLabelStyle: {
      fontSize: '20px',
      fontWeight: '700',
      fill: '#000'
    },
    waateekaaLeyendStyle: true,
    hiddenSpanBinValues: true,
    barGraphSize: 60,
    title: 'WaaTeeKaa Monitoring Dashboard',
    tabTitle: 'WaaTeeKaa Monitoring Dashboard - Solardashboard',
    logo: `${url}/images/waateekaa/logo.svg`,
    icon: `${url}/images/waateekaa/favicon.ico`,
    bottomCardConfig: {
      images: {
        card4: `${url}/images/waateekaa/bottom-card-image.svg`
      },
      topTitle: 'Total energy generated equals',
      hideTopIcon: true,
      iconsOverride: [
        {
          type: EQUIVALENCES.CARBON,
          iconName: getCardIconName('Carbon Dioxide')
        }
      ]
    },
    bottomImageGraphPage: `${url}/images/waateekaa/bottom-graph-image.svg`,
    allowMinDataGraph: true
    // rotateSeconds: 15,
    // rotatePages: [
    //   {
    //     path: routePaths.public.home,
    //     duration: 15
    //   },
    //   {
    //     path: routePaths.public.weeklyGraph,
    //     duration: 15
    //   },
    //   {
    //     path: routePaths.public.monthlyGraph,
    //     duration: 15
    //   },
    //   {
    //     path: routePaths.public.annualGraph,
    //     duration: 15
    //   }
    // ]
  },
  {
    name: 'kokosing',
    domain: 'https://kokosing-solardashboard.corbinbrooks.com',
    hostname: 'kokosing-solardashboard.corbinbrooks.com',
    siteId: 65694,
    annapolisId: 65695,
    installDateSelected: '2021-09-16T00:00:00-04:00',
    lifetimeProductionUnit: 'megawatt', //Options should be: kilowatt, megawatt, gigawatt
    wheelColor: {},
    meterColor: '#F0801D',
    weatherIconColor: '#F0801D',
    meterFontColor: '#fff',
    bottomCardsColor: '#080B0B',
    bottomCardsIconColor: '#F0801D',
    showMonthValues: true,
    showTotalValues: false,
    chartId: 15,
    showYearProduction: false,
    showChartLabels: true,
    showChartDots: false,
    chartLeyendAlignment: 'left', // left | center |right
    lineGraphWidth: 4,
    barGraphSize: 65,
    weatherComponent: {
      paddingLeft: '4rem',
      gapForecast: '2rem'
    },
    chartFilterDefaultRange: {
      spanData: 'week',
      binData: 'Bin1Hour',
      optionsArray: [
        { label: 'Week', value: 'week' },
        { label: 'Month', value: 'month' }
      ]
    },
    sideBar: {
      infoFontSize: 14,
      climateEnabled: true,
      climate: {
        iconPosition: 'left',
        containerPadding: '0rem',
        containerMargin: '0rem'
      },
      showForecast: false
    },
    systemDataColor: '#EF811F',
    title: 'Westerville Campus Solar Dashboard',
    tabTitle: 'Kokosing - Solardashboard',
    logo: `${url}/images/kokosing/kokosing-logo.svg`,
    icon: `${url}/images/kokosing/favicon.ico`,
    bottomCardConfig: {
      topTitle: 'Total energy generated equals',
      hideTopIcon: true
    },
    hideMeteredProductionText: true,
    graphPageTitle: 'Solar Array Dashboard | Annual Production Summary',
    buldingImages: [
      `${url}/images/kokosing/westerville1.JPG`,
      `${url}/images/kokosing/westerville2.JPG`,
      `${url}/images/kokosing/annapolis-junction.jpg`
    ],
    slideShowImages: {
      siteOverviewImage: `${url}/images/kokosing/kokosing-overview.JPG`,
      environmentImage: `${url}/images/kokosing/environment.svg`,
      solarSolutionsImage: `${url}/images/kokosing/solar-solutions.svg`
    },
    allowMinDataGraph: true,
    customGraphHeight: 'calc(85% + 1rem)',
    chartYaxisLabelStyle: {
      fontSize: '16px',
      fontWeight: '500',
      fill: '#000'
    },
    rotateSeconds: 30,
    rotatePages: [
      {
        path: routePaths.public.home,
        duration: 30
      },
      {
        path: routePaths.public.solarSolutions,
        duration: 30
      },
      {
        path: routePaths.public.aerial,
        duration: 30
      },
      {
        path: routePaths.public.dashboard,
        duration: 30
      },
      {
        path: routePaths.public.graph,
        duration: 30
      },
      {
        path: routePaths.public.sites,
        duration: 30
      }
    ]
  }
];

export default sitesConfigList;
